import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FileAddOutlined,
  CloudUploadOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as csv from 'csvtojson';
import { Button, Card, Checkbox, DatePicker, Space, Spin, Steps, Table, Tag, Tooltip, Upload, message } from 'antd';
import { useSelector } from 'react-redux';
import { optifluxColors } from '../../layout/colors';
import { GraphContainer, StepActionContainer, StepContentContainer, UploadContent, UploadPageContainer } from './style';
import UloGraph from './ulo-graph';
import CultivarsService from '../../service/cultivars';
import SelectInput from './inputs/select';
import SitesService from '../../service/sites';
import DashboardService from '../../service/dashboard';
import UloService from '../../service/ulo';
import DeleteConfirmationModal from '../../components/modals/delete';

const { Step } = Steps;

const cultivarsService = new CultivarsService();
const dashboardService = new DashboardService();
const sitesService = new SitesService();

export const uloFields = [
  {
    label: (
      <span>
        CO<sub>2</sub> set
      </span>
    ),
    key: 'co2Set',
  },
  {
    label: (
      <span>
        O<sub>2</sub> set
      </span>
    ),
    key: 'o2Set',
  },
  {
    label: <span>Temp. set</span>,
    key: 'tempSet',
  },
  {
    label: 'Days',
    key: 'day',
  },
];

const initialValues = {
  cultivars: [],
  containers: [],
  sites: [],
};

const UploadUlo = () => {
  const globalStates = useSelector(state => state?.Dashboard);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fileName, setFileName] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [uloData, setUloData] = useState();
  const [startDate, setStartDate] = useState(moment());
  const [selectedValues, setSelectedValues] = useState(initialValues);
  const [csvValues, setCsvValues] = useState();
  const [fieldMapping, setFieldMapping] = useState({});
  const [autoStartDate, setAutoStartDate] = useState({ isEnabled: false, groupedContainers: {} });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { data: sites, isLoading: isLoadingSites } = useQuery({
    queryKey: ['sites'],
    queryFn: () => sitesService.getSitesbyId(globalStates?.companyId ? { company_id: globalStates?.companyId } : {}),
    select: response => response.data.data,
  });
  const { data: cultivars, isLoading } = useQuery({
    queryKey: ['cultivars'],
    queryFn: () => cultivarsService.getCultivars(globalStates?.companyId),
    select: response => response.data.data,
  });
  const { data: containers, isLoading: isLoadingRooms } = useQuery({
    queryKey: ['containers'],
    queryFn: () => dashboardService.getTableData({ company: globalStates.companyId, license: 'watchdog' }),
    select: response => response.data.data,
  });
  const { data: uloProfiles, isLoading: isLoadingProfiles, refetch: profilesRefetch } = useQuery({
    queryKey: ['profiles', globalStates?.companyId],
    queryFn: () => UloService.getProfiles(globalStates?.companyId),
    select: response => response.data.data,
  });

  const filteredRooms = useMemo(() => {
    if (!containers?.length) {
      return null;
    }
    return containers.filter(room => {
      let cultivarsCheck = true;
      let sitesCheck = true;
      if (selectedValues.cultivars?.length) {
        cultivarsCheck = room.room_contents?.some(content => selectedValues.cultivars.includes(content.cultivar_id));
      }
      if (selectedValues.sites?.length) {
        sitesCheck = selectedValues.sites?.includes(room.site_id);
      }
      return cultivarsCheck && sitesCheck;
    });
  }, [selectedValues.cultivars, selectedValues.sites, containers]);

  const determineStartDate = useCallback(
    container => {
      if (!container.room_contents?.length || !autoStartDate?.isEnabled) {
        return startDate;
      }
      const smartFreshBatch = container.room_contents.find(content => content.smart_fresh);
      if (smartFreshBatch) return smartFreshBatch.smart_fresh;
      const latestBatch = container.room_contents.sort((a, b) => new Date(b.harvest) - new Date(a.harvest))[0];
      return latestBatch.harvest;
    },
    [startDate, autoStartDate],
  );

  const containersGroupedByAutoStartDate = useMemo(() => {
    if (!autoStartDate?.isEnabled || !containers?.length) return null;
    const containersToLoop = selectedValues?.containers?.length
      ? filteredRooms.filter(container => selectedValues.containers.includes(container.id))
      : filteredRooms;
    const containersWithStartDate = containersToLoop.map(container => {
      return { ...container, startDate: determineStartDate(container) };
    });
    return containersWithStartDate.reduce((acc, value) => {
      const startDates = Object.keys(acc);
      const currentStartDate = new Date(value.startDate).toDateString();
      if (startDates.includes(currentStartDate)) {
        acc[currentStartDate].push({ id: value.id, name: value.name });
      } else {
        acc[currentStartDate] = [{ id: value.id, name: value.name }];
      }
      return acc;
    }, {});
  }, [autoStartDate.isEnabled, containers, determineStartDate, filteredRooms, selectedValues.containers]);

  const uploadUlo = useMutation({
    mutationFn: () =>
      UloService.applyProfile({
        fileName,
        profileData: uloData,
        companyId: globalStates?.companyId,
        startDate,
        selectedRoomIds: selectedValues?.containers?.length
          ? selectedValues?.containers
          : filteredRooms?.map(room => room.id),
        startDateGroups: containersGroupedByAutoStartDate,
      }),
    onSuccess: () => message.success('Uploading complete!'),
    onError: () => message.error('Something went wrong, try again later.'),
  });

  const deleteUlo = useMutation({
    mutationFn: uloProfileId => UloService.deleteProfile(uloProfileId),
    onSuccess: () => {
      message.success('Deleted successfully!');
      setShowDeleteModal(false);
      setSelectedValues(initialValues);
      setUloData(null);
      profilesRefetch();
      setFileName(null);
    },
    onError: () => message.error('Something went wrong, try again later.'),
  });

  const handleUpload = () => {
    uploadUlo.mutate();
  };

  useEffect(() => {
    const { containers: selectedRooms } = selectedValues;
    if (selectedRooms?.length && selectedRooms.some(id => !filteredRooms.map(room => room.id).includes(id))) {
      const remainingRooms = selectedRooms.filter(roomId => {
        const isPresent = filteredRooms.some(room => room?.id === roomId);
        return isPresent;
      });
      setSelectedValues(st => ({ ...st, containers: remainingRooms }));
    }
  }, [filteredRooms, selectedValues]);

  const getDataFromCsv = file => {
    try {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', event => {
        csv()
          .fromString(event.target.result)
          .then(result => {
            setCsvValues(result);
          });
      });
      fileReader.readAsText(file);
    } catch (error) {
      console.error(error);
    }
  };
  const handleDateChange = date => {
    setStartDate(date);
  };
  const dummyRequest = ({ onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleFileChange = useCallback(fileInfo => {
    const file = fileInfo?.fileList[0]?.originFileObj;
    setFileList(fileInfo?.fileList);
    if (!file) {
      setCsvValues(null);
      setUloData(null);
      setFileName(null);
      setFieldMapping({});
    } else {
      setFileName(file.name);
      getDataFromCsv(file);
    }
  }, []);

  const handleSelectionChange = (selections, selectionKey) => {
    setSelectedValues(st => ({ ...st, [selectionKey]: selections }));
  };

  const getDataFromSelectionKey = useCallback(
    key => {
      switch (key) {
        case 'cultivars':
          return cultivars;
        case 'containers':
          return containers;
        case 'sites':
          return sites;
        default:
          return [];
      }
    },
    [containers, cultivars, sites],
  );

  const getSelectionSummary = useCallback(
    selectionKey => {
      const currentlySelected = selectedValues[selectionKey];
      if (currentlySelected?.length) {
        const data = getDataFromSelectionKey(selectionKey);
        return data.filter(obj => currentlySelected.includes(obj.id)).map(obj => <Tag>{obj.name || obj.title}</Tag>);
      }
      if (!filteredRooms?.length && selectionKey === 'containers') {
        return (
          <span style={{ color: 'red' }}>
            <b>No available containers</b>
          </span>
        );
      }
      return (
        <span>
          <em>{`All available ${selectionKey}`}</em>
        </span>
      );
    },
    [filteredRooms, getDataFromSelectionKey, selectedValues],
  );

  const handlePreviouslyUploaded = useCallback(
    selection => {
      setSelectedValues(st => ({ ...st, profile: selection }));
      const profile = uloProfiles.find(p => p.id === selection);
      setFileName(profile?.name);
      setUloData(profile?.profileSetValues);
    },
    [uloProfiles],
  );

  const handleFieldMapping = useCallback(
    (customName, fieldKey) => {
      if (fieldKey) {
        setFieldMapping(st => ({ ...st, [fieldKey]: customName }));
      } else {
        const newFieldMapping = { ...fieldMapping };
        const previousKey = Object.keys(fieldMapping).find(key => fieldMapping[key] === customName);
        if (previousKey) {
          delete newFieldMapping[previousKey];
          setFieldMapping({ ...newFieldMapping });
        }
      }
    },
    [fieldMapping],
  );

  const mapCsvValues = useCallback(() => {
    const convertedUloProfileData = csvValues.map(oldValues => {
      return Object.keys(oldValues).reduce((acc, oldKey) => {
        const newField = Object.keys(fieldMapping).find(key => fieldMapping[key] === oldKey);
        const oldFieldValue = oldValues[oldKey];
        acc[newField] = oldFieldValue;
        return acc;
      }, {});
    });
    setUloData(convertedUloProfileData);
  }, [csvValues, fieldMapping]);
  const newFieldSelectOptions = useMemo(
    () =>
      uloFields.map(field => ({
        label: field.label,
        value: field.key,
        disabled: !!Object.keys(fieldMapping).includes(field.key),
      })),
    [fieldMapping],
  );
  const handleAutoChange = useCallback(e => {
    setAutoStartDate(st => ({ ...st, isEnabled: e.target.checked }));
  }, []);

  const autoStartDateSummary = useMemo(() => {
    return containersGroupedByAutoStartDate && Object.keys(containersGroupedByAutoStartDate)?.length ? (
      <>
        <Space>
          {Object.keys(containersGroupedByAutoStartDate).map(date => (
            <Card title={date} size="small">
              {containersGroupedByAutoStartDate[date]?.map(containerInfo => (
                <Tag key={containerInfo.id}>{containerInfo.name}</Tag>
              ))}
            </Card>
          ))}
        </Space>
      </>
    ) : null;
  }, [containersGroupedByAutoStartDate]);
  const handleDeleteUloProfile = useCallback(() => {
    const profile = uloProfiles?.find(uloProfile => uloProfile.name === fileName);
    deleteUlo.mutate(profile?.id);
  }, [deleteUlo, fileName, uloProfiles]);
  const items = useMemo(
    () => [
      {
        title: 'Select CSV to apply',
        icon: <FileAddOutlined />,
        content: isLoadingProfiles ? (
          <Spin />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ display: 'flex', position: 'relative' }}>
              <SelectInput
                identifier="uloSelect"
                handleChange={handlePreviouslyUploaded}
                options={uloProfiles.map(profile => ({ label: profile.name, value: profile.id }))}
                placeholder="Select previously uploaded ULO Profile"
                selectedValues={selectedValues.profile}
                disabled={!!csvValues}
              />
              {selectedValues.profile && (
                <Button
                  style={{
                    height: '80%',
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    transform: 'translate(105%, 12.5%)',
                  }}
                  danger
                  onClick={() => setShowDeleteModal(true)}
                >
                  Delete Profile
                </Button>
              )}
              <DeleteConfirmationModal
                warningMessage="Are you sure you want to delete this ULO profile? This will also stop the automatic daily set for all containers/rooms that this ULO profile was applied to in the past."
                handleCancel={() => setShowDeleteModal(false)}
                handleConfirm={handleDeleteUloProfile}
                visible={showDeleteModal}
              />
            </div>
            <span style={{ margin: '5px' }}>Or</span>
            <UploadContent>
              <Upload
                accept=".csv"
                multiple={false}
                customRequest={dummyRequest}
                onChange={handleFileChange}
                fileList={fileList}
              >
                <Button color={optifluxColors.lightGreen} type="primary" disabled={fileName}>
                  Browse CSV file
                </Button>
              </Upload>
            </UploadContent>
            {csvValues?.length > 3 && (
              <>
                <div style={{ width: '100%' }}>
                  <Table
                    style={{ borderRadius: '1rem !important' }}
                    title={() => 'Map your CSV fields'}
                    pagination={false}
                    bordered
                    scroll={{ x: 1000, y: 600 }}
                    columns={Object.keys(csvValues[0]).map(key => ({
                      title: (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <SelectInput
                            identifier={`fieldSelect-${fieldMapping}`}
                            handleChange={fieldKey => handleFieldMapping(key, fieldKey)}
                            options={newFieldSelectOptions}
                            placeholder="Map to field"
                            disabled={
                              !newFieldSelectOptions?.filter(obj => !obj.disabled)?.length &&
                              !Object.values(fieldMapping).includes(key)
                            }
                            defaultValue={Object.keys(fieldMapping).find(fieldKey => fieldMapping[fieldKey] === key)}
                            style={{ width: '150px', textAlign: 'center' }}
                          />
                          <span style={{ marginTop: '5px' }}>{key}</span>
                        </div>
                      ),
                      dataIndex: key,
                      key,
                    }))}
                    dataSource={csvValues.toSpliced(3, csvValues.length - 1)}
                  />
                </div>
                <Button
                  style={{ margin: '1rem' }}
                  type="primary"
                  disabled={newFieldSelectOptions?.filter(obj => !obj.disabled)?.length}
                  onClick={mapCsvValues}
                >
                  Apply field mapping
                </Button>
              </>
            )}
          </div>
        ),
      },

      {
        title: 'Sites',
        icon: <UnorderedListOutlined />,
        content: isLoadingSites ? (
          <Spin />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ marginBottom: '1rem' }}>
              <em>Apply ULO profile only to certain sites</em>{' '}
              <Tooltip title="By default, all sites are used">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
            <SelectInput
              identifier="sitesSelect"
              handleChange={selections => handleSelectionChange(selections, 'sites')}
              options={sites.map(site => ({ label: site.name, value: site.id }))}
              placeholder="Select Sites (optional)"
              selectedValues={selectedValues.sites}
              mode="multiple"
            />
          </div>
        ),
      },
      {
        title: 'Cultivars',
        icon: <UnorderedListOutlined />,
        content: isLoading ? (
          <Spin />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ marginBottom: '1rem' }}>
              <em>Apply ULO profile only to certain cultivars</em>{' '}
              <Tooltip title="By default, all cultivars are used">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
            <SelectInput
              identifier="cultivarSelect"
              handleChange={selections => handleSelectionChange(selections, 'cultivars')}
              options={cultivars.map(cultivar => ({ label: cultivar.title, value: cultivar.id }))}
              placeholder="Select Cultivars (optional)"
              selectedValues={selectedValues.cultivars}
              mode="multiple"
            />
          </div>
        ),
      },
      {
        title: 'Containers/rooms',
        icon: <UnorderedListOutlined />,
        content: isLoadingRooms ? (
          <Spin />
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ marginBottom: '1rem' }}>
              <em>Apply ULO profile only to certain containers</em>{' '}
              <Tooltip title="By default, all available containers/rooms are used">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
            <SelectInput
              identifier="roomSelect"
              handleChange={selections => handleSelectionChange(selections, 'containers')}
              options={filteredRooms?.map(room => ({ label: room.name, value: room.id }))}
              placeholder="Select Containers (optional)"
              selectedValues={selectedValues.containers}
              mode="multiple"
            />
          </div>
        ),
      },
      {
        title: 'Start Date',
        icon: <CalendarOutlined />,
        content: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{ marginBottom: '1rem' }}>
              <em>Change start date of ULO profile</em>{' '}
            </span>
            <DatePicker onChange={handleDateChange} value={startDate} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: '2px solid lightgrey',
                borderRadius: '8px',
                width: '50%',
                margin: '1rem',
                padding: '1rem',
              }}
            >
              <Checkbox checked={autoStartDate?.isEnabled} onChange={handleAutoChange}>
                Enable Automatic Start Date{' '}
              </Checkbox>
              <span style={{ fontSize: '0.85rem', margin: '5px 5px 0 5px', textAlign: 'left' }}>
                <em>
                  For each room/container, the latest harvest date will be used as start date of the ULO profile. If
                  there is a SmartFresh&#8482; treatment, the treatment date will be used instead. If the room/container
                  has no registered content, the selected start date above will be used.
                </em>
              </span>
            </div>
            {autoStartDateSummary}
          </div>
        ),
      },
      {
        title: 'Summary and Upload',
        icon: <CloudUploadOutlined />,
        content: (
          <>
            <Space>
              <Card title="File name" size="small">
                <Tag>{fileName}</Tag>
              </Card>
              <Card title="ULO start date" size="small">
                <Tag style={{ userSelect: 'none' }}>
                  {autoStartDate?.isEnabled ? (
                    <Tooltip title={<div>{autoStartDateSummary}</div>}>Auto</Tooltip>
                  ) : (
                    startDate?.format('MMMM Do YYYY')
                  )}
                </Tag>
              </Card>
              <Card title="Selected sites" size="small">
                {getSelectionSummary('sites')}
              </Card>
              <Card title="Selected cultivars" size="small">
                {getSelectionSummary('cultivars')}
              </Card>
              <Card title="Selected containers" size="small">
                {getSelectionSummary('containers')}
              </Card>
            </Space>
          </>
        ),
      },
    ],
    [
      isLoadingProfiles,
      handlePreviouslyUploaded,
      uloProfiles,
      selectedValues.profile,
      selectedValues.sites,
      selectedValues.cultivars,
      selectedValues.containers,
      csvValues,
      handleDeleteUloProfile,
      showDeleteModal,
      handleFileChange,
      fileList,
      fileName,
      newFieldSelectOptions,
      mapCsvValues,
      isLoadingSites,
      sites,
      isLoading,
      cultivars,
      isLoadingRooms,
      filteredRooms,
      startDate,
      autoStartDate.isEnabled,
      handleAutoChange,
      autoStartDateSummary,
      getSelectionSummary,
      fieldMapping,
      handleFieldMapping,
    ],
  );

  return (
    <UploadPageContainer>
      <h2 style={{ marginBottom: '2rem' }}>ULO Profile Upload</h2>
      <Steps current={currentIndex}>
        {items.map(item => (
          <>
            <Step {...item} />
          </>
        ))}
      </Steps>
      <StepContentContainer>{items[currentIndex].content}</StepContentContainer>
      <StepActionContainer>
        <Button onClick={() => setCurrentIndex(st => st - 1)} disabled={currentIndex <= 0}>
          Previous
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          type="primary"
          onClick={() => setCurrentIndex(st => st + 1)}
          disabled={currentIndex >= items.length - 1 || !uloData}
        >
          Next
        </Button>
        {currentIndex === items.length - 1 && (
          <Button
            style={{ marginLeft: 8 }}
            type="primary"
            onClick={handleUpload}
            disabled={!filteredRooms?.length || !fileName}
          >
            Upload
          </Button>
        )}
      </StepActionContainer>
      {uloData && (
        <GraphContainer>
          <UloGraph uloData={uloData} startDate={startDate} fields={uloFields} />
        </GraphContainer>
      )}
    </UploadPageContainer>
  );
};

export default UploadUlo;
