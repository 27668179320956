export const TOKEN = 'opfe-token';
export const API_URL =
  process.env.NODE_ENV !== 'production' ? 'http://localhost:9001' : 'https://staging-app.optiflux.world/backend';
export const MAX_AGE = 60 * 60 * 2; // 2 hours
export const ADMIN_ROLE = 'admin';
export const SUPER_ADMIN_ROLE = 'superAdmin';
export const COMPANY = 'company';
export const SUPER_ADMIN = 1;

export const ROLES = {
  1: 'superadmin',
  2: 'admin',
  3: 'user',
  4: 'grower',
  5: 'lab',
  6: 'intake',
};

export const homeKey = 'home';
const qualityInsightsKey = 'qualityInsights';
const storageInsightsKey = 'storageInsights';
const dcaKey = 'DCA';
export const storageOptimumKey = 'storageOptimum';
export const storageOptimumRoute = 'storage-optimum';
export const optiControlKey = 'optiControl';

export const sections = {
  [homeKey]: { label: 'Home', route: 'home' },
  [qualityInsightsKey]: { label: 'QualityInsights', route: 'quality-insights' },
  [storageInsightsKey]: { label: 'StorageInsights', route: 'storage-insights' },
  [storageOptimumKey]: {
    label: 'StorageOptimum',
    route: storageOptimumRoute,
    key: storageOptimumKey,
    type: 'group',
  },
  [optiControlKey]: { label: 'OptiControl', route: 'opticontrol', parent: storageOptimumRoute },
  [dcaKey]: { label: 'DCA', route: 'dca', parent: storageOptimumRoute },
};

export const measurementModuleSection = { label: 'Measurements', route: 'measurements' };

export const subNavigation = {
  [optiControlKey]: [{ label: 'System', route: 'system' }],
  storageInsights: [{ label: 'Event log', route: 'event-log' }],
  [homeKey]: [measurementModuleSection],
};
