import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { Form, Input, Button } from 'antd';
import { AuthWrapper } from './style';
import Heading from '../../../../components/heading/heading';
import LoginService from '../../../../service/login';
import { openNotificationError, openNotificationSuccess } from '../../../../utility/helpers';

const loginService = new LoginService();

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const handleSubmit = values => {
    loginService
      .forgetPassword(values?.email)
      .then(() => {
        openNotificationSuccess('success', 'Email sent, check your inbox to reset your password.');
        form.resetFields();
      })
      .catch(err => {
        openNotificationError('error', `${err?.response?.data?.Message}`);
      });
  };
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  return (
    <AuthWrapper>
      <div className="auth-contents">
        <Form name="forgotPass" form={form} onFinish={handleSubmit} layout="vertical">
          <Heading as="h3">Forgot Password?</Heading>
          <p className="forgot-text">
            Enter the email address you used when you joined and we’ll send you instructions to reset your password.
          </p>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input placeholder="name@example.com" />
          </Form.Item>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                disabled={
                  !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                }
                className="btn-reset"
                htmlType="submit"
                type="primary"
                size="large"
              >
                Send Reset Instructions
              </Button>
            )}
          </Form.Item>
          <p className="return-text">
            Return to <NavLink to="/">Sign In</NavLink>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
};

export default ForgotPassword;
