import queryString from 'query-string';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text
    .split(' ')
    .slice(0, size)
    .join(' ')}...`;
};

export { ellipsis };

export const roundNumberFromString = value => {
  return Math.round(Number(value) * 1000) / 1000;
};

export const roundString = (value, precision = 3) => {
  return parseFloat(value).toFixed(precision);
};

export const getSelectedRoomsFromQueryParams = params => {
  let fromQuery = queryString.parse(params, { arrayFormat: 'comma', parseNumbers: true })?.selectedRooms;
  if (!Array.isArray(fromQuery)) fromQuery = [fromQuery];
  return fromQuery[0] ? fromQuery : [];
};

export const getSelectedDatasetFromQueryParams = params => {
  return queryString.parse(params)?.selected;
};

export function convertToFahrenheit(celsius) {
  const fahrenheit = celsius * 1.8 + 32;
  return parseFloat(Math.round(fahrenheit * 1000) / 1000);
}

export function convertPaToPsi(pascalValue) {
  const psi = parseFloat(pascalValue) / 6894.76;
  return Math.round(psi * 1000) / 1000;
}

export const formatPressure = (value, isMetric) => {
  if (!value) {
    return '';
  }
  return isMetric ? (value / 100).toFixed(1) : convertPaToPsi(value).toFixed(2);
};

export const formatTemp = (value, isMetric) => {
  if (!value) {
    return '';
  }
  return isMetric ? Number(value)?.toFixed(1) : convertToFahrenheit(value)?.toFixed(1);
};

const setMeasurementTypes = {
  external: { label: 'External Set' },
  manual: { label: 'Manual Set' },
  ulo: { label: 'ULO Set' },
};

export const setStyle = (external, manual, ulo) => ({
  color: external ? '#D3D3D3' : manual ? '#899499' : ulo ? '#808080' : 'lightgrey',
});

export function getSetTitle(externalSet, manualSet, uloSet) {
  return externalSet
    ? setMeasurementTypes.external.label
    : manualSet
    ? setMeasurementTypes.manual.label
    : uloSet
    ? setMeasurementTypes.ulo.label
    : '';
}
export const units = {
  minutes: 'minutes',
  seconds: 'seconds',
  percentage: '%',
  ppm: 'ppm',
};

export const correctUnit = (setting, value) => {
  if (setting.unit === units.minutes && setting.target === units.seconds) {
    return Math.round(value / 60);
  }
  if (setting.unit === units.percentage && setting.target === units.ppm) {
    return Math.round(value / 10000);
  }
  return value;
};

export const getDatagroupingUnitForInjectionVolumes = days => {
  if (days > 30) {
    return [['week', [1]]];
  }
  return [['day', [1]]];
};

export const externalFromDataset = (dataset, roomData) => {
  switch (dataset) {
    case 'temperature':
      return roomData.externalTemperature;
    case 'temperature_pt100':
      return roomData.externalTemperatureFruit;
    case 'external-set':
      return roomData.externalSet1;
    default:
      return roomData.externalMeasurements1;
  }
};

const crimsonRed = '#b80f0a';
const denimBlue = '#79BAEC';
const avocadoGreen = '#568203';

export const determineColor = (axisColor, dataset) => {
  switch (dataset) {
    case 'o2':
      return denimBlue;
    case 'co2':
      return crimsonRed;
    case 'temperature_pt100':
      return avocadoGreen;
    default:
      return axisColor;
  }
};
